export enum AcaciumBreakpointsQueries {
  mobile = '(max-width: 766.98px)',
  tablet = '(min-width: 767px) and (max-width: 968.98px)',
  desktop = '(min-width: 969px)',
}

export enum AcaciumDeviceEnum {
  mobile = 'ACACIUM_MOBILE',
  tablet = 'ACACIUM_TABLET',
  desktop = 'ACACIUM_DESKTOP',
  unknown = 'ACACIUM_UNKNOWN',
}
